import { getAll } from "@/actions/group"
import AlertError from "@/components/AlertError"
import { SelectCompanyAndUser } from "@/components/admin/SelectCompanyAndUser"
import { BtnNextStep } from "@/components/buttons/BtnNextStep"
import SelectedReusable from "@/components/company/massActions/SelectedReusable"
import { CardDescription } from "@/components/ui/card"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { SelectItem } from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import useLoadingAndError from "@/hooks/useLoadingAndError"
import { cn } from "@/lib/utils"
import '@/styles/scrollModify.css'
import { zodResolver } from "@hookform/resolvers/zod"
import { Image } from "lucide-react"
import { ReactNode, useEffect, useMemo } from "react"
import { useForm } from 'react-hook-form'

import notificationService from '@/actions/notifications'
import { Checkbox } from "@/components/ui/checkbox"
import { Label } from "@/components/ui/label"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"

const formSchemaAddNotification = z.object({
  title: z.string().min(3, { message: 'El título debe de contener como mínimo 3 caracteres' }).max(160),
  description: z.string().max(3300),
  redirectURL: z.string().min(1, { message: 'La URL de redirección debe de contener como mínimo 1 caracter' }),
  image: z.string().optional(),
  type: z.string(),
  popup: z.boolean(),
  companies: z.array(z.any()).optional(),
  roles: z.array(z.string().optional()).optional(),
  users: z.array(z.any())
})

const defaultValues = {
  title: '',
  description: '',
  image: '',
  type: 'article',
  redirectURL: '',
  popup: false,
  companies: [],
  users: [],
  roles: []
}

export const roles = [{
  label: 'Vendedor',
  code: 'user'
}, {
  label: 'Supervisor',
  code: 'super'
}, {
  label: 'Comercial',
  code: 'comercial'
}, {
  label: 'Encargado de proyecto',
  code: 'project_manager'
}
]

const typeOfNotificationOptions = [
  {
    value: 'article',
    label: 'Articulo'
  },
  {
    value: 'video',
    label: 'Video'
  }
]

export default function DialogAddNewNotification({ open, onCLose, refetchNotification }) {
  const { isError, isLoading, setError, setIsLoading } = useLoadingAndError()

  const isModifyNotification = useMemo(() => typeof open !== 'boolean', [open])

  const { data: allCompanies } = useQuery({
    queryFn: getAll,
    queryKey: ['all-groups']
  })

  const contextForm = useForm<z.infer<typeof formSchemaAddNotification>>({
    resolver: zodResolver(formSchemaAddNotification),
    defaultValues
  })

  const [miniature, typeOfNotificationSelected, companies, roles, users]: any = ['image', 'type', 'companies', 'roles', 'users']
    .map(field => contextForm.watch(field as 'image' | 'type' | 'companies'))

  const usersFilterByCompany = useMemo(() => {
    const usersToReturn = companies ? companies.map(c => c.users) : []

    if (isModifyNotification) {
      usersToReturn.push(open.users)
    }

    return usersToReturn.flat()
  }, [companies, open, isModifyNotification])

  const allIdsOfUsersSelected = useMemo(() => {
    if (roles?.length == 0) return users.map(user => user._id)

    return users.filter(obj =>
      obj?.roles.some(role => roles?.includes(role))
    ).map(user => user._id)
  }, [users, roles])

  useEffect(() => {
    if (isError) setError('')
    if (!isModifyNotification) return contextForm.reset(defaultValues)
    else {
      const roles = Array.from(new Set(open.users.map(user => user.roles).flat()))

      contextForm.reset({ ...defaultValues, ...open, roles })
    }
  }, [isModifyNotification, open])

  const handleSubmit = async (values: z.infer<typeof formSchemaAddNotification>) => {
    setIsLoading(true)
    const data = { ...values }
    delete data.companies
    delete data.roles

    data.users = allIdsOfUsersSelected

    notificationService.createOrModify({ data, id: open?._id })
      .then(() => {
        refetchNotification()
        onCLose()
      })
      .catch(() => {
        setError("Ocurrió un error al querer agregar una nueva notificación.")
      })
      .finally(() => setIsLoading(false))
  }

  return <Dialog open={!!open} onOpenChange={onCLose}>
    <DialogContent className="sm:max-w-[630px] max-h-[95vh] p-0 overflow-y-auto overflow-x-hidden relative modifyScroll">
      <DialogHeader className="p-6 py-5">
        <DialogTitle>Crear notificación</DialogTitle>
      </DialogHeader>
      {isError && <AlertError description={isError} />}
      <div>
        <Form {...contextForm}>
          <form onSubmit={contextForm.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-6" id="formAddNewPlatformNotification">
            <FormField
              control={contextForm.control}
              name="title"
              render={({ field }) => (
                <ItemField label={'Título'} >
                  <Input
                    {...field}
                    placeholder="Ej: ¡Nuevas novedades llegarán a la plataforma!" />
                </ItemField>
              )} />
            <FormField
              control={contextForm.control}
              name="description"
              render={({ field }) => (
                <ItemField label={'Descripción'} >
                  <Textarea
                    {...field}
                    placeholder="Ej: Descubre emocionantes novedades en nuestra plataforma. ¡Únete ahora para estar al tanto!" className="resize-none min-h-[70px]" />
                </ItemField>
              )} />
            <FormField
              control={contextForm.control}
              name="type"
              render={({ field }) => (
                <ItemField label={`Tipo de notificación`} >
                  <SelectedReusable field={field} placeholder="Seleccionar tipo" style={{}}>
                    {
                      typeOfNotificationOptions.map(({ label, value }) => (
                        <SelectItem
                          value={value}
                          key={value}>
                          {label}
                        </SelectItem>
                      ))
                    }
                  </SelectedReusable>
                </ItemField>
              )} />
            <div className="flex gap-2">
              <div className="flex-1 flex-grow">
                <div className="w-full flex items-center justify-center max-h-[130px] h-full rounded-md border overflow-hidden border-dashed border-primary">
                  {!miniature
                    ? <Image className="w-5 h-5 text-primary" />
                    : <img src={miniature as string} className="w-full object-cover h-full" />}
                </div>
              </div>
              <div className="flex-[2] flex flex-col gap-2">
                <FormField
                  control={contextForm.control}
                  name="image"
                  render={({ field }) => (
                    <ItemField label={`Miniatura del ${typeOfNotificationSelected === 'video' ? 'video' : 'articulo'}`} >
                      <Input
                        {...field}
                        placeholder="Ej: https://panel.getventia.com/logo.png" />
                    </ItemField>
                  )} />
                <FormField
                  control={contextForm.control}
                  name="redirectURL"
                  render={({ field }) => (
                    <ItemField label={'URL a redirigir'} >
                      <Input
                        {...field}
                        placeholder="Ej: https://panel.getventia.com/blog/2" />
                    </ItemField>
                  )} />
              </div>
            </div>
            <div className="w-full flex flex-col flex-1 z-10 relative">
              <SelectCompanyAndUser
                contextForm={contextForm}
                activeTitle={false}
                onChange={(name: any, value) => {
                  contextForm.setValue(name, value)
                }}
                companies={allCompanies ? allCompanies : []}
                usersCompany={usersFilterByCompany}
              />
            </div>
          </form>
        </Form>
      </div>
      <DialogFooter className='sticky py-2 bg-white -bottom-[1px] px-6 mt-0 border-t'>
        <div className="flex items-center bg-white justify-between w-full">
          <CardDescription>
            {
              allIdsOfUsersSelected.length > 0 && `Se notificará a ${allIdsOfUsersSelected.length} usuarios`
            }
          </CardDescription>
          <BtnNextStep
            loading={isLoading}
            hiddenArrow
            form="formAddNewPlatformNotification"
            type="submit">
            Crear
          </BtnNextStep>
        </div>
      </DialogFooter>
    </DialogContent>
  </Dialog>
}


interface ItemFieldProps {
  children: ReactNode,
  label?: string | ReactNode | null,
  className?: string
}

export function ItemField({ children, label = '', className = '' }: ItemFieldProps) {
  return <FormItem className={cn("flex flex-col flex-1", className)}>
    {label && <FormLabel>{label}</FormLabel>}
    <FormControl >
      {children}
    </FormControl>
    <FormMessage />
  </FormItem>
}