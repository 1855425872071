import { cn } from '@/lib/utils'
import { ArrowRight, Loader2 } from 'lucide-react'
import { forwardRef } from 'react'
import { Button, ButtonProps } from '../ui/button'

export interface BtnNextStepProps extends ButtonProps {
    loading?: boolean
    hiddenArrow?: boolean
    classNameArrow?: string,
}

const BtnNextStep = forwardRef<HTMLButtonElement, BtnNextStepProps>(({ className, classNameArrow = '', variant = 'outline', loading = false, hiddenArrow = false, children, disabled, ...props }, ref) => {
    return (
        <Button
            type="button"
            className={cn("group shrink-0", className)}
            ref={ref}
            disabled={loading || disabled}
            variant={variant as 'outline'}
            {...props}
        >
            {loading
                ? <Loader2 className="h-4 shrink-0 w-4 animate-spin" />
                : children
            }
            {!hiddenArrow && !loading && <ArrowRight className={cn("w-[16px] group-hover:translate-x-1 transition-transform h-[16px] ml-1", classNameArrow)} />}
        </Button>
    )
})

export { BtnNextStep }
