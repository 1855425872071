import { Select, SelectContent, SelectGroup, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";

interface PropsSelectedReusable {
  field: any
  label?: string
  children: React.ReactNode
  placeholder: string
  styles?: string
  style?: Object
  isDisabled?: boolean
}

export default function SelectedReusable({ field, label, children, placeholder, styles = '', isDisabled = false, style = { marginTop: 0 } }: PropsSelectedReusable) {
  return <Select onValueChange={field.onChange} value={field.value} disabled={isDisabled} >
    <SelectTrigger className={styles} style={style}>
      <SelectValue placeholder={placeholder} />
    </SelectTrigger>
    <SelectContent>
      <SelectGroup className="max-h-[250px] m-0">
        {
          label && <SelectLabel>
            {label}
          </SelectLabel>
        }
        {children}
      </SelectGroup>
    </SelectContent>
  </Select>
}
