export class ArrayFunctions {

    public deleteByInx<T>(
        elements: Array<T>,
        inx: number,
        elementToDelete: number = 1): Array<T> {

        const copy = [...elements]
        copy.splice(inx, elementToDelete)

        return copy
    }

    public deleteOrAddElementByID<T>(idToDelete: string, array: Array<any> = [], identificator = ''): T {
        const allDataArray = [...array]
        const findIndex = allDataArray.findIndex(item => (identificator ? item?.[identificator] : item) === idToDelete)
        if (findIndex === -1) {
            allDataArray.push(idToDelete)
        } else {
            allDataArray.splice(findIndex, 1)
        }
        return allDataArray as T
    }

    public removeByIndex<T>(array: Array<T>, inx: number): Array<T> { //<=====Notacion big O(1)
        const newArray = [...array];
        newArray[inx] = newArray[newArray.length - 1];
        newArray.pop();
        return newArray;
    }

    public removeBySplice<T>(array: Array<T>, inx: number): Array<T> {
        const newArray = [...array];
        newArray.splice(inx, 1);
        return newArray;
    }
}

export function deleteElementOfArrayByInx<T>(
    elements: Array<T>,
    inx: number,
    elementToDelete: number = 1): Array<T> {

    const copy = [...elements]
    copy.splice(inx, elementToDelete)

    return copy
}

export function removeElemenentOfArrayByIndex<T>(array: Array<T>, inx: number): Array<T> { //<=====Notacion big O(1)
    const newArray = [...array];
    newArray[inx] = newArray[newArray.length - 1];
    newArray.pop();
    return newArray;
}

export function cumulativelyAddAnArray(array: Array<number>): number[] {
    function calculateCumulativeArrayRecursive(array, index = 0, accumulator: Array<number> = []) {
        if (index === array.length) return accumulator;

        const sum = (accumulator.length === 0 ? 0 : accumulator[accumulator.length - 1]) + array[index];
        return calculateCumulativeArrayRecursive(array, index + 1, [...accumulator, sum]);
    }

    const cumulativeArrayRecursive = calculateCumulativeArrayRecursive(array);
    return cumulativeArrayRecursive
}

export const deleteOrAddElementByID = (idToDelete: string, array: Array<any> = [], elementToAdd?: any, identificator = '') => {
    const allDataArray = [...array]
    const findIndex = allDataArray.findIndex(item => (identificator ? item?.[identificator] : item) === idToDelete)
    if (findIndex === -1) {
        allDataArray.push(elementToAdd || idToDelete)
    } else {
        allDataArray.splice(findIndex, 1)
    }
    return allDataArray
}

export const reorder = <T>(list: Array<any>, startIndex: number, endIndex: number) => {
    const result: Array<T> = Array.from([...list]);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const handleDeleteItemOfTheArray = <T>(element: string | Record<string, any>, array: Array<any> = [], identificator = ''): T => {
    const allDataArray = [...array]
    const findIndex = allDataArray
        .findIndex(item => (identificator ? item?.[identificator] : item) === (identificator ? element?.[identificator] : element))

    if (findIndex === -1) {
        allDataArray.push(element)
    } else {
        allDataArray.splice(findIndex, 1)
    }

    return allDataArray as T
}

export const arrayFunctions = new ArrayFunctions()

