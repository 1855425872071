import { INotification } from "@/components/Header/NavBar";
import { fetcher } from "@/helpers/fetcher";
import { TOrderBy } from "@/hooks/useGetNotifications";
import { TypeOfPlatformNotification } from "@/models/PlatformNotification";
import { INotificationAdmin } from "@/pages/grupos/platformNotifications";

interface INotificationsFetch {
    notifications: Array<INotification>,
    countDocuments: number,
    countAllNotifications: number
}

export interface IPropsGet {
    all?: boolean
    page?: number
    orderBy: TOrderBy,
    popup?: boolean
    limit?: number
    type?: TypeOfPlatformNotification
}

class NotificationServiceUser {
    public url: string

    constructor() {
        this.url = '/notification'
    }

    getAllByUser({ all, page = 1, limit = 10, type, popup, orderBy }: IPropsGet) {
        const baseUrl = '/notification/user';

        const queryParams = new URLSearchParams({
            page: page.toString(),
            limit: limit.toString(),
            all: all ? 'true' : '',
            type: type || '',
            popup: popup ? 'true' : '',
            orderBy: orderBy || '',
        });

        const url = `${baseUrl}?${queryParams.toString()}`;
        return fetcher<INotificationsFetch>({ url, options: { method: 'GET' } })
    }

    public modifyTheInteractionByTheUser({ isRejected = false, id }) {
        return fetcher({
            url: `/notification/user?id=${id}${isRejected ? '&isRejected=true' : ''}`,
            options: {
                method: 'PATCH',
            }
        })
    }
}

class NotificationService extends NotificationServiceUser {

    public getAllByAdmin(id: string | null | undefined = '') {
        return fetcher<{ notifications: Array<INotificationAdmin> }>(
            { url: `/notification${id ? `?id=${id}` : ''}`, options: { method: 'GET' } })
    }

    public createOrModify({ data, id = "" }: { data: any, id: string }) {
        const method = id ? 'PUT' : 'POST'

        return fetcher({
            url: `/notification${id ? `?id=${id}` : ''}`,
            options: {
                method,
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": 'application/json'
                }
            }
        })
    }

    public delete(id: string) {
        return fetcher({
            url: `/notification?id=${id}`,
            options: {
                method: 'DELETE',
            }
        })
    }

    public disabled(id: string) {
        return fetcher({
            url: `/notification?id=${id}`,
            options: {
                method: 'PATCH',
            }
        })
    }

    public getUsersOfNotification({ id, status = ['all'], page = 1, limit = 20 }: { id: string, status: Array<string>, page?: number, limit?: number }) {
        return fetcher<{ notifications: Array<INotificationAdmin> }>(
            {
                url: `/notification?id=${id}&users=true&page=${page}&limit=${limit}&status=${status.join(',')}`,
                options: { method: 'GET' }
            })
    }
}

export default new NotificationService()