import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Label } from "@/components/ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { AccountExecutive } from "@/interfaces/Interface"
import { AlertCircle, ChevronDown } from "lucide-react"
import { Button } from "../ui/button"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { useEffect, useMemo, useState } from "react"
import useDebounce from "../core/functions/useDebouce"
import AccountManager from '@/actions/manager/index'
import { cn } from "@/lib/utils"

interface ISelectManagerProps {
    managers: Array<AccountExecutive>,
    managerID: string,
    externalOnChange?: (value: string) => void
    externalValue?: string
    companyID?: string
    btnVariant?: any
}

export default function SelectManager({ managers, managerID, companyID, externalOnChange, externalValue, btnVariant = 'ghost' }: ISelectManagerProps) {
    const [lastID, setLastID] = useState<string>(managerID)
    const [isError, setError] = useState('')
    const [managerSelectedID, setManagerSelected] = useState<string>(managerID || '')

    const managerSelected = useMemo(() => managers.find(manager => manager._id === (externalValue ? externalValue : managerSelectedID)), [managerSelectedID, externalValue])
    const debounceID = useDebounce(managerSelectedID, 500)

    useEffect(() => {
        if (companyID) {
            if (debounceID != '' && typeof debounceID === 'string' && debounceID !== lastID) {
                AccountManager.assign({ companyID, idManager: managerSelectedID })
                    .then(data => {
                        if (!data) {
                            setError(data)
                            return setManagerSelected(lastID)
                        }
                        setError('')
                        setLastID(managerSelectedID)
                    })
                    .catch(() => {
                        setError('Ocurrió un error al querer guardar el encargado')
                        setManagerSelected(lastID)
                    })
            }
        }
    }, [debounceID])

    return <Popover>
        <PopoverTrigger asChild>
            <Button variant={btnVariant} className="flex items-center text-[13px] gap-2 text-slate-500">
                {
                    managerSelected && <div className="w-[28px] relative overflow-hidden h-[28px] rounded-full shrink-0 bg-slate-300">
                        <img
                            src={managerSelected.photoPath}
                            className="w-full h-full overflow-hidden"
                            alt={`Image porfile of ${managerSelected.name}`} />
                    </div>
                }
                <p>{managerSelected ? `${managerSelected.name} ${managerSelected.lastname}` : 'Seleccionar encargado'}</p>
                <ChevronDown size={14} />
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 p-0">
            <div className="div">
                {
                    isError && <div className="p-2">
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>
                                {isError}
                            </AlertDescription>
                        </Alert>
                    </div>
                }
                <RadioGroup value={externalValue || managerSelectedID} onValueChange={(value) => {
                    externalOnChange ? externalOnChange(value) : setManagerSelected(value)
                }}>
                    <Command>
                        <CommandInput placeholder="Buscar encargado..." />
                        <CommandList>
                            <CommandEmpty>No se encontro el encargado...</CommandEmpty>
                            <CommandGroup>
                                {
                                    managers.map(manager => (
                                        <CommandItem
                                            className="flex items-center gap-1"
                                            onSelect={() => {
                                                externalOnChange
                                                    ? externalOnChange(manager._id)
                                                    : setManagerSelected(manager._id)
                                            }}>
                                            <RadioGroupItem value={manager._id} />
                                            <ItemManager fullName={`${manager.name} ${manager.lastname}`} photo={manager.photoPath} />
                                        </CommandItem>
                                    ))
                                }
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </RadioGroup>
            </div>
        </PopoverContent>
    </Popover>
}

export function ItemManager({ fullName, photo, className = "" }) {
    return <div className="flex items-center gap-2">
        <div className={cn(`w-4 h-4 rounded-full overflow-hidden shrink-0`, className)}>
            <img
                src={photo}
                className="w-full h-full object-cover"
                alt={`Photo porfile of ${fullName}`} />
        </div>
        <Label>{fullName}</Label>
    </div>
}