import { ItemField, roles } from '@/components/grupos/notification/DialogAddNewNotification'
import { Button } from '@/components/ui/button'
import { CardTitle } from '@/components/ui/card'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { FormField } from '@/components/ui/form'
import { handleDeleteItemOfTheArray } from '@/helpers/arrayFunctions'
import '@/styles/scrollModify.css'
import { CheckIcon, ChevronDown } from 'lucide-react'
import { useRef } from 'react'
import { UseFormReturn } from 'react-hook-form'
import SelectManager from '../comercial/SelectManager'
import { Checkbox } from '../ui/checkbox'
import { Label } from '../ui/label'

interface Props {
    contextForm: UseFormReturn<any>
    onChange: (name: string, value: string | string[]) => void
    usersCompany: any
    activeTitle?: boolean
    allManagers?: Array<any>
    companies?: Array<any>
}

export function SelectCompanyAndUser({
    contextForm,
    onChange,
    usersCompany = [],
    allManagers,
    activeTitle = true,
    companies
}: Props) {

    const x = useRef()

    const handleSelectedCompany = (element, arr, field) => {
        const newValues: Array<any> = handleDeleteItemOfTheArray(element, [...arr], '_id')
        let addValuesUser;

        if (newValues.length > arr.length) {
            addValuesUser = [...usersCompany, ...element.users]
        } else {
            const arrIds = arr.map(company => company.users.map(user => user._id)).flat()
            addValuesUser = usersCompany.filter((user: { _id: string }) => !arrIds.includes(user._id))
        }

        onChange('users', addValuesUser)
        field.onChange(newValues)
    }

    const handleSelectedAllCompany = (field) => {
        if (companies) {
            if (field.value.length >= companies.length) {
                field.onChange([])
                onChange('users', [])
            } else {
                field.onChange(companies)
                onChange('users', companies
                    .map(company => company.users)
                    .map(user => user)
                    .flat())
            }
        }
    }

    return <>
        {
            activeTitle && <div>
                <CardTitle className='text-base'>
                    Encargado, vendedores y roles
                </CardTitle>
            </div>
        }
        <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
            {
                companies && <div>
                    <FormField
                        control={contextForm.control}
                        name='companies'
                        render={({ field }) => {
                            const label = field.value.map(company => company.displayName).join(', ')
                            return <ItemField className='mt-2'>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <div className='z-[999999999999999999]'>
                                            <Button
                                                type='button'
                                                variant="outline"
                                                title={label}
                                                className='w-full overflow-hidden max-w-[100%] text-[13px] gap-2 text-slate-500'>
                                                {
                                                    field.value.length === 0
                                                        ? <span>Seleccionar empresa</span>
                                                        : <span className='text-ellipsis overflow-hidden max-w-[60%] text-primary whitespace-nowrap'>
                                                            {label}
                                                        </span>
                                                }
                                                <ChevronDown size={14} />
                                            </Button>
                                        </div>
                                    </DropdownMenuTrigger>
                                      <div>
                                    <DropdownMenuContent className="w-80  relative p-0">
                                      <Command className='h-full overflow-hidden'>
                                            <CommandInput placeholder='Empresas...'></CommandInput>
                                            <CommandList className='modifyScroll max-h-[250px] relative'>
                                                <CommandGroup className="p-0">
                                                    {
                                                        companies.map((company: any) => (
                                                            <CommandItem
                                                                key={company._id}
                                                                value={company._id}
                                                                onSelect={() => {
                                                                    handleSelectedCompany(company, field.value, field)
                                                                }}
                                                            >
                                                                <CheckBox selected={field.value.map(c => c._id).includes(company._id)} />
                                                                <span>{company.displayName} ({company.businessName})</span>
                                                            </CommandItem>
                                                        ))
                                                    }
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                        <BtnSelectAll onAction={() => handleSelectedAllCompany(field)} />
                                    </DropdownMenuContent>
                                      </div>
                                </DropdownMenu>
                            </ItemField>
                        }} />
                </div>
            }
            {
                allManagers && <div>
                    <FormField
                        control={contextForm.control}
                        name='agent'
                        render={({ field }) => (
                            <ItemField className='mt-2 w-full'>
                                <SelectManager
                                    btnVariant={'outline'}
                                    externalValue={field.value}
                                    externalOnChange={(value: string) => field.onChange(value)}
                                    managers={allManagers || []}
                                    managerID={field.value}
                                />
                            </ItemField>
                        )} />
                </div>
            }
            <div className='py-2 gap-2'>
                <FormField
                    control={contextForm.control}
                    name='users'
                    render={({ field }) => {
                        const label = field.value.map(u => `${u.name} ${u.lastname}`).join(', ')
                        return <ItemField>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button
                                        disabled={usersCompany?.length == 0}
                                        title={label}
                                        variant="outline"
                                        className='w-full text-[13px] gap-2 text-slate-500'>
                                        {
                                            field.value.length === 0
                                                ? <span>Seleccionar Vendedores</span>
                                                : <span className='text-ellipsis overflow-hidden max-w-[60%] text-primary whitespace-nowrap'>
                                                    {label}
                                                </span>
                                        }
                                        <ChevronDown size={14} />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-80 relative overflow-hidden p-0">
                                    <Command className='h-full overflow-hidden'>
                                        <CommandList className='modifyScroll max-h-[250px] relative'>
                                            <CommandInput placeholder="Buscar vendedor" />
                                            <CommandEmpty>No se encontro el vendedor.</CommandEmpty>
                                            {
                                                usersCompany?.map((user: any) => (
                                                    <CommandItem
                                                        key={user._id}
                                                        onSelect={() => {
                                                            field.onChange(handleDeleteItemOfTheArray(user, field.value, '_id'))
                                                        }}
                                                    >
                                                        <CheckBox selected={field.value.map(u => u._id).includes(user._id)} />
                                                        <span>{user.name} {user.lastname}</span>
                                                    </CommandItem>
                                                ))
                                            }
                                        </CommandList>
                                    </Command>
                                    <BtnSelectAll onAction={() => {
                                        if (field.value.length >= usersCompany?.length) {
                                            field.onChange([])
                                        } else {
                                            field.onChange(usersCompany)
                                        }
                                    }} />
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </ItemField>
                    }} />
            </div>
        </div>
        <SelectRol
            roles={roles}
            control={contextForm.control}
            name='roles'
            onChange={(value) => onChange('roles', value)} />
    </>
}

export function CheckBox({ selected }) {
    return <div
        className={`mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary ${selected
            ? "bg-primary text-primary-foreground"
            : "opacity-50 [&_svg]:invisible"}`}
    >
        <CheckIcon className={"h-4 w-4"} />
    </div>
}

interface PropsSelectRol {
    roles: Array<{
        label: string,
        code: string
    }>
    name: string
    control: any
    onChange: (value: any) => void
}

export function SelectRol({ roles, name, control, onChange }: PropsSelectRol) {

    const handleSelectedRol = (code, array: Array<any>) => {
        const data: Array<string> = handleDeleteItemOfTheArray(code, array)
        onChange(data)
    }

    return <div>
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <ItemField className='mt-2'>
                    <ul className='flex items-center gap-3'>
                        {
                            roles.map(rol => (
                                <li key={rol.code}>
                                    <div className="flex items-center space-x-2">
                                        <Label className="font-normal">{rol.label}</Label>
                                        <Checkbox checked={field.value?.includes(rol.code)}
                                            onCheckedChange={() => handleSelectedRol(rol.code, field.value)} />
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </ItemField>
            )} />
    </div>
}

export function BtnSelectAll({ onAction }: { onAction: () => void }) {
    return <Button
        variant={'ghost'}
        onClick={onAction}
        className='w-full rounded-t-none border-t'>
        Seleccionar todos
    </Button>
}