import { fetcher } from '@/helpers/fetcher'
import { AccountExecutive } from '@/interfaces/Interface'

class AccountManager {
    public create({ data }) {
        return fetcher({
            defaultContentType: true,
            url: '/accountManager',
            method: 'POST',
            body: data
        })
    }

    public delete({ id }) {
        return fetcher<{ group: any }>({
            url: `/accountManager?id=${id}`,
            method: 'DELETE',
        })
    }

    public assign({ companyID, idManager }) {
        return fetcher<{ group: any }>({
            url: `/group/assignManager?idGroup=${companyID}&idManager=${idManager}`,
            method: 'PATCH'
        })
    }

    public modify({ data, id }) {
        return fetcher<{ managers: Array<AccountExecutive> }>({
            url: `/accountManager?id=${id}`,
            defaultContentType: true,
            method: 'PATCH',
            body: data
        })
    }

    public getAll() {
        return fetcher<{ managers: Array<AccountExecutive> }>({ url: `/accountManager` })
    }

    public getByCompany() {
        return fetcher<{ manager: AccountExecutive }>({ url: `/accountManager/company` })
    }

    public attemptedContactByFeature(featureName: string) {
        return fetcher<{ success: true }>({
            url: `/accountManager/attemptedContactByFeature?featureName=${featureName}`,
            method: 'POST'
        })
    }
}

export default new AccountManager()