import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToggle } from '@/hooks/useToggle';
import { EyeIcon, EyeOff } from 'lucide-react';
import React from 'react';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputPassword = React.forwardRef<HTMLInputElement, InputProps>(({ ...props }) => {
    const [showPassword, togglePassoword] = useToggle()

    return <div className='relative'>
        <Input
            {...props}
            type={showPassword ? 'text' : 'password'} />
        <Button
            type='button'
            variant={'ghost'}
            className='absolute rounded-full top-0 right-2 [&>svg]:shrink-0 w-[35px] h-[35px] bottom-0 m-auto shrink-0   flex justify-center items-center text-neutral-400'
            onClick={() => togglePassoword()}>
            {
                showPassword
                    ? <EyeOff aria-hidden="true" size={19} />
                    : <EyeIcon aria-hidden="true" size={19} />
            }
        </Button>
    </div>
})

export default InputPassword