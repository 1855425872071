import { Dispatch, SetStateAction, useCallback, useState } from "react";

export function useToggle<T>(defaultValue?: boolean): [T | boolean, (value?: any) => void, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T | boolean>(defaultValue ?? false);

  const toggle = (value?: any) =>
    setValue((prevValue) => typeof value !== 'undefined' && typeof value == 'boolean' ? !!value : !prevValue)

  return [value as T, toggle, setValue];
};
