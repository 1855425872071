import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { cn } from "@/lib/utils";
import { AlertCircle } from "lucide-react"

export default function AlertError({
    title = 'Error',
    description = '',
    soportError = false,
    className = ''
}: {
    title?: string,
    description: string | null | boolean,
    soportError?: boolean,
    className?: string
}) {
    if (!description) return null;

    return (
        <Alert variant="destructive" className={cn(className)}>
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription className="flex flex-col">
                <span>
                    {description}
                </span>
                {
                    soportError && <span>
                        Por favor, inténtentelo nuevamente. Si el problema persiste, pongase en contacto con soporte.
                    </span>
                }
            </AlertDescription>
        </Alert>
    )
}
